// https://www.vujson.com/post/iframe-postmessage-with-promise

import {transactions_sa} from "./transactions";
import {client_sa} from "./client";
import {account_sa} from "./account";
import {cards_sa} from "./cards";
import {onboard_sa} from "./onboard";
import {beneficiaries_sa} from "./beneficiaries";
import {payments_sa} from "./payments";
import {rates_sa} from "./rates";
import {rewards_sa} from "./rewards";

export {
    transactions_sa,
    client_sa,
    account_sa,
    cards_sa,
    beneficiaries_sa,
    payments_sa,
    rates_sa,
    onboard_sa,
    rewards_sa
}
