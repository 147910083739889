import {Pfm} from "../../interface/account.interface";
import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";


export const account_pfm = {
  list: {
    /**
     * List accounts
     */
    all(): Promise<Pfm> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("pfm", "account.list.all",  undefined)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * List nonInvestec
     */
    nonInvestec(): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage(
          "pfm",
          "account.list.nonInvestec",

          undefined
        )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  add: {
    /**
     * List nonInvestec
     */
    // fastLinkAccount(): Promise<PostMessageResponse> {
    //   return new Promise((resolve, reject) => {
    //     sdkPostMessage(
    //       "pfm",
    //       "account.add.fastLinkAccount",
    //
    //       undefined
    //     )
    //       .then((res) => {
    //         resolve(res.data);
    //       })
    //       .catch((err) => {
    //         reject(err);
    //       });
    //   });
    // },
  },
  refresh: {
    /**
     * List nonInvestec
     */
    all(): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage(
          "pfm",
          "account.refresh.all",

          undefined
        )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * List nonInvestec
     * @param accountId
     */
    single(accountId: string, ): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage(
          "pfm",
          "account.refresh.single",

          accountId
        )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * refresh fastlink account
     * @param accountId
     */
    // fastLinkAccount(accountId: string): Promise<PostMessageResponse> {
    //   return new Promise((resolve, reject) => {
    //     sdkPostMessage(
    //       "pfm",
    //       "account.refresh.fastLinkAccount",
    //
    //       undefined
    //     )
    //       .then((res) => {
    //         resolve(res.data);
    //       })
    //       .catch((err) => {
    //         reject(err);
    //       });
    //   });
    // },
  },
  /**
   * remove account
   * @param accountId
   */
  remove(accountId: string, ): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage("pfm", "account.remove",  accountId)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  edit: {
    /**
     * edit fastlink account
     * @param accountId
     */
    fastLinkAccount(accountId: string ): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage(
          "pfm",
          "account.edit.fastLinkAccount",

          accountId
        )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * edit account
     * @param account
     */
    // single(account: any): Promise<PostMessageResponse> {
    //   return new Promise((resolve, reject) => {
    //     sdkPostMessage(
    //       "pfm",
    //       "account.edit.single",
    //
    //       account
    //     )
    //       .then((res) => {
    //         resolve(res.data);
    //       })
    //       .catch((err) => {
    //         reject(err);
    //       });
    //   });
    // },
  },
};
