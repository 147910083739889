// https://www.vujson.com/post/iframe-postmessage-with-promise
import {client_uk} from "./client";
import {transactions_uk} from "./transactions";
import {account_uk} from "./account";
import {beneficiaries_uk} from "./beneficiaries";
import {payments_uk} from "./payments";

export {
    client_uk,
    transactions_uk,
    account_uk,
    beneficiaries_uk,
    payments_uk
}
