import {generateUUID} from "./helpers/uuid";

export function sdkPostMessage(api: string, method_id: string, msg?: any): Promise<any> {
  return new Promise((resolve, reject): void => {
    const seq = generateUUID()

    window.addEventListener('message', (res) => {
      if (res && res.data && res.data.__seq && res.data.__seq === seq && res.data.__sdk === 'invsy') {
        resolve(res.data);
      }
    }, false);

    const data = {
      __sdk: 'invsy',
      __msg : msg,
      __seq: seq,
      __id: method_id,
      __api: api
    }

    parent.postMessage(data, '*');
  });
}
