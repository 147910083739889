import {UkClient} from "../../interface/client.interface";
import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";


export const client_uk = {
  /**
   * Get client details
   */
  get(): Promise<PostMessageResponse> {
    return new Promise<PostMessageResponse>((resolve, reject) => {
      sdkPostMessage("pbuk", "client.get",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
