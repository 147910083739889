import {Transaction} from "../../interface/transaction.interface";
import {sdkPostMessage} from "../../../util";


export const transactions_wisa = {
  /**
   * List transactions
   * @param accounts
   * @param startDate
   * @param endDate
   * @param categoryCodes
   * @param instrumentCode
   * @param isAllAccounts
   */
  list(
    accounts: Array<{number:string,type:string}>,
    startDate: string,
    endDate: string,
    instrumentCode: string,
    categoryCodes: Array<string>,
    isAllAccounts: boolean
  ): Promise<Transaction[]> {
    return new Promise<any>((resolve, reject) => {
      sdkPostMessage(
        "wisa",
        "transactions.list",

        {
          accounts,
          startDate,
          endDate,
          instrumentCode,
          categoryCodes,
          isAllAccounts
        }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
