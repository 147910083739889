import {platform} from "./platform";
import {data} from "./data";
import {api} from "./api/api";
import {MobilePlatform} from "./native-mobile/platfrom-mobile";

export const invsy = {
  platform: platform,
  mobile: MobilePlatform,
  data: data,
  api: api
};

interface Window {
  invsy: typeof invsy;
}

declare const window: Window;

window.invsy = invsy;
