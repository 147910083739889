import {Code, EnvironmentVariables, Executions, OAuthToken} from "../../interface/programmable-banking";
import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";


export const programmable_banking = {
    oauth: {
        get(): Promise<OAuthToken> {
            return new Promise((resolve, reject) => {
              sdkPostMessage(
                    "programmable",
                    "oauth.get",

                    undefined
                )
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    card: {
          /**
           * Toggle programmable banking ON or OFF on a card
           * @param accountNumber
           */
          get(
            accountNumber: number,
          ): Promise<PostMessageResponse> {
            return new Promise<PostMessageResponse>((resolve, reject) => {
              sdkPostMessage(
                "programmable",
                "cards.get",

                {accountNumber}
              )
                .then((res) => {
                  resolve(res.data);
                })
                .catch((err) => {
                  reject(err);
                });
            });
          },
        /**
         * Toggle programmable banking ON or OFF on a card
         * @param accountNumber
         * @param cardKey
         */
        toggle(
            accountNumber: number,
            cardKey: string,
        ): Promise<PostMessageResponse> {
            return new Promise<PostMessageResponse>((resolve, reject) => {
                sdkPostMessage(
                    "programmable",
                    "cards.toggle",

                    {
                        accountNumber,
                        cardKey,
                    }
                )
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        /**
         * Get logs
         * @param accountNumber
         * @param cardKey
         */
        logs(
            accountNumber: number,
            cardKey: string,
        ): Promise<PostMessageResponse> {
            return new Promise((resolve, reject) => {
                sdkPostMessage(
                    "programmable",
                    "cards.logs",

                    {
                        accountNumber,
                        cardKey,
                    }
                )
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    code: {
        /**
         * get code
         * @param accountNumber
         * @param cardKey
         */
        get(
            accountNumber: number,
            cardKey: string,
        ): Promise<PostMessageResponse> {
            return new Promise((resolve, reject) => {
                sdkPostMessage(
                    "programmable",
                    "cards.getCode",

                    {
                        accountNumber,
                        cardKey,
                    }
                )
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        /**
         * Get published code
         * @param accountNumber
         * @param cardKey
         */
        getPublished(
            accountNumber: number,
            cardKey: string,
        ): Promise<PostMessageResponse> {
            return new Promise((resolve, reject) => {
                sdkPostMessage(
                    "programmable",
                    "cards.getPublishedCode",

                    {
                        accountNumber,
                        cardKey,
                    },
                )
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        /**
         * Save code to card
         * @param accountNumber
         * @param cardKey
         * @param code
         */
        save(
            accountNumber: number,
            cardKey: string,
            code: string,
        ): Promise<PostMessageResponse> {
            return new Promise((resolve, reject) => {
                sdkPostMessage(
                    "programmable",
                    "cards.saveProgrammableCode",

                    {
                        accountNumber,
                        cardKey,
                        code,
                    }
                )
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

      /**
       *
       * @param accountNumber
       * @param cardKey
       * @param codeId
       * @param code
       */
      publish: function (
        accountNumber: number,
        cardKey: string,
        code: string,
        codeId: string,
      ): Promise<PostMessageResponse> {
        return new Promise((resolve, reject) => {
          sdkPostMessage(
            "programmable",
            "cards.publishProgrammableCode",

            {
              accountNumber,
              cardKey,
              code,
              codeId,
            }
          )
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    },
    env: {
        /**
         * Get environment variables
         * @param accountNumber
         * @param cardKey
         */
        get(
            accountNumber: number,
            cardKey: string,
        ): Promise<PostMessageResponse> {
            return new Promise((resolve, reject) => {
                sdkPostMessage(
                    "programmable",
                    "cards.envVars",

                    {
                        accountNumber,
                        cardKey,
                    },
                )
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        /** Save environment variables
         *
         * @param accountNumber
         * @param cardKey
         * @param variables
         */
        save(
            accountNumber: number,
            cardKey: string,
            variables: string,
        ): Promise<PostMessageResponse> {
            return new Promise((resolve, reject) => {
                sdkPostMessage(
                    "programmable",
                    "cards.saveEnvVars",

                    {
                        accountNumber,
                        cardKey,
                        variables,
                    }
                )
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
};
