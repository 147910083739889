import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";

export const account_wiuk = {
  /**
   * List accounts
   * @param referenceDate
   * @param referenceCurrencyCode
   */
  list(
    referenceDate: string,
    referenceCurrencyCode: string,
  ): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage(
        "wiuk",
        "account.list",

        {
          referenceDate,
          referenceCurrencyCode
        }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

