import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";

export const holdings_wisa = {
  /**
   * List holdings
   */
  list(accounts:Array<any>,referenceCurrencyCode:string,referenceDate:string): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage("wisa", "holdings.list",  {
        Accounts: accounts,
        ReferenceCurrencyCode: referenceCurrencyCode,
        ReferenceDate: referenceDate
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
