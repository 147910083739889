import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";

export const market_data_wisa = {
  indices: {
    international: {
      get(): Promise<PostMessageResponse> {
        return new Promise((resolve, reject) => {
          sdkPostMessage(
            "wisa",
            "indices.international.get",

            undefined
          )
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      history(
        ID: string,
        FromDate: string,
        ToDate: string,
      ): Promise<PostMessageResponse> {
        return new Promise((resolve, reject) => {
          sdkPostMessage(
            "wisa",
            "indices.international.history",

            {id:ID,fromDate:FromDate,toDate:ToDate}
          )
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    },
    local: {
      get(): Promise<PostMessageResponse> {
        return new Promise((resolve, reject) => {
          sdkPostMessage(
            "wisa",
            "indices.local.get",

            undefined
          )
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      history(
        ID: string,
        FromDate: string,
        ToDate: string,
      ): Promise<PostMessageResponse> {
        return new Promise((resolve, reject) => {
          sdkPostMessage(
            "wisa",
            "indices.local.history",

            {id:ID,fromDate:FromDate,toDate:ToDate}
          )
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    },
  },
  currency: {
    get(): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("wisa", "currency.get",  undefined)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    history(
      ID: string,
      FromDate: string,
      ToDate: string
    ): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("wisa", "currency.history", {id:ID,fromDate:FromDate,toDate:ToDate} )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  commodity: {
    get(): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("wisa", "commodity.get",  undefined )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    history(
      ID: string,
      FromDate: string,
      ToDate: string,
    ): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage(
          "wisa",
          "commodity.history",

          {id:ID,fromDate:FromDate,toDate:ToDate}
        )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  dividends: {
    get(): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("wisa", "dividends.get",  undefined)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
