import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";

export const account_uk = {
  /**
   * List accounts
   */
  list(): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage("pbuk", "account.list",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
