import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";

export const cards_uk = {
  /**
   *
   * @param account_number
   * @returns
   */
  list(account_number: number): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage(
        "pbuk",
        "cards.list",

        account_number
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};
