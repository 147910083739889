import {sdkPostMessage} from "../../../util";
import {BeneficiaryPaymentDataUK} from "../../interface/payments.interface";
import {PostMessageResponse} from "../../../types/sdk-response.interface";


export const payments_uk = {
  beneficiary(paymentData: BeneficiaryPaymentDataUK): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage(
        "pbuk",
        "payments.beneficiary",

          paymentData,
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
