import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";

export const beneficiaries_uk = {
  list(): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage("pbuk", "beneficiaries.list",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  create(): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage(
        "pbuk",
        "beneficiaries.create",

        undefined,
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
