import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";

export const transactions_wiuk = {
  /**
   * List transactions
   * @param startDate
   * @param endDate
   * @param referenceCurrencyCode
   * @param statementType
   * @param account
   */
  list(
    startDate: string,
    endDate: string,
    referenceCurrencyCode: string,
    statementType: string,
    account: { Number: string; Type: string },
  ): Promise<PostMessageResponse> {
    return new Promise<PostMessageResponse>((resolve, reject) => {
      sdkPostMessage(
        "wiuk",
        "transactions.list",

        {
          startDate,
          endDate,
          referenceCurrencyCode,
          statementType,
          account,
        }
      )
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
