import {
  account_sa,
  beneficiaries_sa,
  cards_sa,
  client_sa,
  payments_sa,
  rates_sa,
  rewards_sa,
  transactions_sa
} from "./private-client/pbsa";
import {account_uk, beneficiaries_uk, client_uk, payments_uk, transactions_uk} from "./private-client/pbuk";
import {account_wisa, holdings_wisa, market_data_wisa, transactions_wisa} from "./private-client/wisa";
import {account_wiuk, asset_allocation_wiuk, holdings_wiuk, transactions_wiuk} from "./private-client/wiuk";
import {account_pfm} from "./private-client/pfm";
import {programmable_banking} from "./private-client/programmable-banking/programmable-banking";
import {cards_uk} from "./private-client/pbuk/cards";

export const api = {
  privateClient: {
    pbsa: {
      transactions: transactions_sa,
      client: client_sa,
      accounts: account_sa,
      card: cards_sa,
      rates: rates_sa,
      rewards: rewards_sa,
      beneficiaries: beneficiaries_sa,
      payments: payments_sa,
    },
    pbuk: {
      client: client_uk,
      transactions: transactions_uk,
      accounts: account_uk,
      card: cards_uk,
      beneficiaries: beneficiaries_uk,
      payments: payments_uk,
    },
    wisa: {
      transactions: transactions_wisa,
      accounts: account_wisa,
      holdings: holdings_wisa,
      market_data: market_data_wisa,
    },
    wiuk: {
      transactions: transactions_wiuk,
      accounts: account_wiuk,
      holdings: holdings_wiuk,
      asset_allocation: asset_allocation_wiuk,
    },
    pfm: {
      accounts: account_pfm,
    },
    programmable_banking: programmable_banking

  },
  intermediaryClient: {},
  businessBankingClient: {},
}
