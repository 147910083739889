import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";


export const beneficiaries_sa = {
  list(): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage("pbsa", "beneficiaries.list",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // create(): Promise<PostMessageResponse> {
  //   return new Promise((resolve, reject) => {
  //     sdkPostMessage(
  //       "pbsa",
  //       "beneficiaries.create",
  //
  //       undefined
  //     )
  //       .then((res) => {
  //         resolve(res.data);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },
};
