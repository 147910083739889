import {sdkPostMessage} from "../../../util";
import {BlockCard, Card} from "../../interface/card.interface";
import {PostMessageResponse} from "../../../types/sdk-response.interface";

export const cards_sa = {
  /**
   *
   * @param account_number
   * @returns
   */
  list(account_number: number): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage(
        "pbsa",
        "cards.list",

         account_number
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   *
   * @returns
   * @param cardData
   */
  block(cardData: BlockCard): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage("pbsa", "cards.block",  cardData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   *
   * @returns
   * @param cardData
   */
  getFullCardDetail(cardData: any): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage("pbsa", "cards.fullDetail",  cardData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
