import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";

export const asset_allocation_wiuk = {
  /**
   * get asset allocation
   * @param referenceDate
   * @param referenceCurrencyCode
   * @param account
   */
  get(
    referenceDate: string,
    referenceCurrencyCode: string,
    account: { Number: string; Type: string }
  ): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage(
        "wiuk",
        "assetAllocation.get",

        {
          referenceDate,
          referenceCurrencyCode,
          account,
        }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
