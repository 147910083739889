import {sdkPostMessage} from "../../../util";
import {Client} from "../../interface/client.interface";
import {PostMessageResponse} from "../../../types/sdk-response.interface";


export const onboard_sa = {

  homeLoan(): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage("pbsa", "onboard.homeLoan",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   *
   * @constructor
   */
  vehicleFinance(): Promise<Client> {
    return new Promise<Client>((resolve, reject) => {
      sdkPostMessage(
        "pbsa",
        "onboard.vehicleFinance",

        undefined
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
