import {Rate} from "../../interface/rates.interface";
import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";


export const rates_sa = {
  /**
   * Get rate details
   */
  get(): Promise<PostMessageResponse> {
    return new Promise<PostMessageResponse>((resolve, reject) => {
      sdkPostMessage("pbsa", "rates.get",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
