import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";


export const transactions_sa = {
  /**
   * Get transaction by transaction id
   * @param transactionData
   */
  get(transactionData : {accountId: string, dateFrom:string,dateTo:string}): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage(
        "pbsa",
        "transactions.get",

        transactionData
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};
