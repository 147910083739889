import {sdkPostMessage} from "../util";
import {PostMessageResponse} from "../types/sdk-response.interface";


export const MobilePlatform = {
  wallet:{
    open() {
      return new Promise<PostMessageResponse>((resolve, reject) => {
        sdkPostMessage("mobilePlatform", "wallet.open",  undefined)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });

    }
  }
};
