import {sdkPostMessage} from "./util";
import {Observable, Subject} from "rxjs";
import {PostMessageResponse} from "./types/sdk-response.interface";
import {IPortfolioEntity, IZaProfileEntity} from "./types/portfolio.interface";
declare global {
  interface Window {
    adobeDataLayer: any;
  }
}

const runningStreams = {portfolioStream:false,profileStream:false}

export const data = {
  getAllUserContext(): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage("data", "getAllUserContext",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUserUID(): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage("data", "getUserUID",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getClientBasicDetails(): Promise<PostMessageResponse> {
    return new Promise((resolve, reject) => {
      sdkPostMessage("data", "getClientBasicDetails",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPersonalisationOffers(id:string,template:string,data:Array<any>){
    sdkPostMessage("data", "getPersonalisationOffers",  {template:template,data:data}).then(res => {
      const elem = document.getElementById(id)
      if(elem){

        const dataLayer = window.adobeDataLayer || [];

        const wrapper = document.createElement('div')
        wrapper.innerHTML = res.data.result;
        const button = wrapper.querySelectorAll('button');
        let tempCounter = 0;


        data.forEach(value => {

            const primaryBtnValue = button[tempCounter];
            const secondaryBtnValue = button[tempCounter+1];

          primaryBtnValue.addEventListener('click',()=> {
              dataLayer.push({
                event: 'click',
                action: 'generic',
                timeStamp: new Date().toISOString(),
                page: {
                  product: {
                    name: 'wpaas',
                  },
                },
                clickInfo: {
                  engagement: {
                    name: 'personalisation-card-click',
                    id:  value.title - value.buttonText,
                    url: value.route,
                    region: 'generic - where did action take place',
                    type: 'CTA|Click',
                    action: 'btn-click',
                  },
                },
              });
              sdkPostMessage("platform", "navigateUrl",  {url: value.route})
            })

          secondaryBtnValue.addEventListener('click',()=> {
            dataLayer.push({
              event: 'click',
              action: 'generic',
              timeStamp: new Date().toISOString(),
              page: {
                product: {
                  name: 'wpaas',
                },
              },
              clickInfo: {
                engagement: {
                  name: 'personalisation-card-click',
                  id:  value.title - value.secondaryButtonText,
                  url: value.secondaryRoute,
                  region: 'generic - where did action take place',
                  type: 'CTA|Click',
                  action: 'btn-click',
                },
              },
            });
            sdkPostMessage("platform", "navigateUrl",  {url: value.secondaryRoute})
          })

          tempCounter += 2
        })

        let shadowElem
        try{
          shadowElem = elem.attachShadow({mode: 'open'})
          shadowElem.appendChild(wrapper)
        } catch (error){
          if(elem.shadowRoot){
            elem.shadowRoot.textContent = ''
            elem.shadowRoot.appendChild(wrapper)
          }
        }
      }
    })
  },
  getClientTypes(): Promise<PostMessageResponse> {
    return new Promise<PostMessageResponse>((resolve, reject) => {
      sdkPostMessage("data", "getClientTypes",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSelectedProfile(): Observable<{
    errors:Array<any>,
    messages:Array<any>,
    result: IZaProfileEntity,
    success:boolean
  }>{
    const $selectedProfile = new Subject<{
      errors:Array<any>,
      messages:Array<any>,
      result: IZaProfileEntity,
      success:boolean
    }>();

    const abortController = new AbortController();

    function getSelectedZaProfileFromEvents(id:string,data:any){
      if (id === 'selectedProfile.stream') {
        $selectedProfile.next({
          errors:[],
          messages:[],
          result: data,
          success:true
        })
      }
    }

    if(!runningStreams.profileStream){
      window.addEventListener('message',res => getSelectedZaProfileFromEvents(res.data.__id,res.data.data),{signal:abortController.signal})
      runningStreams.profileStream = true
    }

    sdkPostMessage("data", "getSelectedProfile",  undefined).then(() => {
      abortController.abort()
      runningStreams.profileStream = false
    })

    return $selectedProfile
  },
  portfolio:{
    getState(): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("data", "portfolio.getState",  undefined)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    excludeFromTotal(payload?:{flag:string,result:boolean}[]) {
      return new Promise((resolve) => {
        sdkPostMessage("data", "portfolio.updatePortfolioExcludes",  payload)
          .then((res) => {
            resolve(res.data);
          })
      });
    },
    getAllPortfolios(currency:string,selectedProfile:any): Observable<{
      errors:Array<any>,
      messages:Array<any>,
      result: IPortfolioEntity,
      success:boolean
    }> {
      const $portfolio = new Subject<{
        errors:Array<any>,
        messages:Array<any>,
        result: IPortfolioEntity,
        success:boolean
      }>();

      let abortController = new AbortController();

      function getPortfoliosFormEvents(id:string,data:any){
        if (id === 'portfolio.stream') {
          if(data === 'done'){
            abortController.abort()
            runningStreams.portfolioStream = false;
          } else {
            $portfolio.next({
              errors:[],
              messages:[],
              result: data,
              success:true
            })
          }
        }
      }

      if(runningStreams.portfolioStream){
        abortController.abort()
        abortController = new AbortController();
      } else {
        runningStreams.portfolioStream = true
      }

      window.addEventListener('message',res => getPortfoliosFormEvents(res.data.__id,res.data.data),{signal:abortController.signal})

      sdkPostMessage("data", "portfolio.getAllPortfolios",  {currency:currency,selectedProfile:selectedProfile}).then(() => {
        abortController.abort()
        runningStreams.portfolioStream = false
      })

      return $portfolio
    },
    getPrivateBankZAPortfolio(currency:string,selectedProfile:any): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("data", "portfolio.getPrivateBankZAPortfolio",  {currency:currency,selectedProfile:selectedProfile})
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPrivateBankUKPortfolio(currency:string): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("data", "portfolio.getPrivateBankUKPortfolio",  {currency:currency,selectedProfile:undefined})
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPrivateBankUKMortgagesPortfolio(currency:string): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("data", "portfolio.getPrivateBankUKMortgagesPortfolio",  {currency:currency,selectedProfile:undefined})
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPrivateBankCIPortfolio(currency:string): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("data", "portfolio.getPrivateBankCIPortfolio",  {currency:currency,selectedProfile:undefined})
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPrivateBankMUPortfolio(currency:string): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("data", "portfolio.getPrivateBankMUPortfolio",  {currency:currency,selectedProfile:undefined})
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getWealthAndInvestmentZAPortfolio(currency:string): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("data", "portfolio.getWealthAndInvestmentZAPortfolio",  {currency:currency,selectedProfile:undefined})
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getWealthAndInvestmentUKPortfolio(currency:string): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("data", "portfolio.getWealthAndInvestmentUKPortfolio",  {currency:currency,selectedProfile:undefined})
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getWealthAndInvestmentGUPortfolio(currency:string): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("data", "portfolio.getWealthAndInvestmentGUPortfolio",  {currency:currency,selectedProfile:undefined})
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getWealthAndInvestmentClickPortfolio(currency:string): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("data", "portfolio.getWealthAndInvestmentClickPortfolio",  {currency:currency,selectedProfile:undefined})
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getWealthAndInvestmentCIPortfolio(currency:string): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("data", "portfolio.getWealthAndInvestmentCIPortfolio",  {currency:currency,selectedProfile:undefined})
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAllNonInvestecAccounts(currency:string): Promise<PostMessageResponse> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("data", "portfolio.getAllNonInvestecAccounts",  {currency:currency,selectedProfile:undefined})
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  lookups:{
    bank: {
      sa: {
        list() {
          return new Promise<PostMessageResponse>((resolve, reject) => {
            sdkPostMessage("data", "lookups.bank.sa",  undefined)
              .then((res) => {
                resolve(res.data);
              })
              .catch((err) => {
                reject(err);
              });
          });
        },
      },
    },
    client: {
      sa: {
        list() {
          return new Promise<PostMessageResponse>((resolve, reject) => {
            sdkPostMessage("data", "lookups.client.sa",  undefined)
              .then((res) => {
                resolve(res.data);
              })
              .catch((err) => {
                reject(err);
              });
          });
        },
      },
    },
  }
};
