import {sdkPostMessage} from "../../../util";
import {RewardsRedemptionInterface} from "../../interface/rewards.interface";


export const rewards_sa = {
  getLanding(accountNumberForRequests:string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      sdkPostMessage("pbsa", "rewards.landing",  {
        accountNumberForRequests:accountNumberForRequests
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPartners(accountNumberForRequests:string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      sdkPostMessage("pbsa", "rewards.partners",  {
        accountId:accountNumberForRequests
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPartnerCategories(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      sdkPostMessage("pbsa", "rewards.partnerCategories",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  redemption(data: RewardsRedemptionInterface): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      sdkPostMessage("pbsa", "rewards.redemption",  {
        categoryId: data.categoryId,
        redemption: data.redemption
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  transfer(data: {fromAccount:string,toAccount:string,transferPoints:number}): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      sdkPostMessage("pbsa", "rewards.transfer",  {
        account:{
          fromAccount:data.fromAccount,
          toAccount:data.toAccount,
        },
        transferPoints: data.transferPoints
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUnitTrustCategories(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      sdkPostMessage("pbsa", "rewards.unitTrustCategories",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUnitTrusts(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      sdkPostMessage("pbsa", "rewards.unitTrusts",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCalculatorSolutions(accountNumberForRequests:string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      sdkPostMessage("pbsa", "rewards.calculator.solutions",  {
        accountNumberForRequests:accountNumberForRequests
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getTransactionTypes(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      sdkPostMessage("pbsa", "rewards.transactionTypes",  undefined)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getTransactions(accountNumberForRequests:string,transactionTypeId:string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      sdkPostMessage("pbsa", "rewards.transactions",  {
        accountNumberForRequests:accountNumberForRequests,
        transactionTypeId:transactionTypeId
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};
