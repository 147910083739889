import {Transaction} from "../../interface/transaction.interface";
import {sdkPostMessage} from "../../../util";
import {PostMessageResponse} from "../../../types/sdk-response.interface";


export const transactions_uk = {
  /**
   * List transactions
   * @param accountId
   * @param startDate
   * @param endDate
   */
  list(
    accountId: string,
    startDate: string,
    endDate: string,
  ): Promise<PostMessageResponse> {
    return new Promise<PostMessageResponse>((resolve, reject) => {
      sdkPostMessage(
        "pbuk",
        "transactions.list",

        {
          accountId,
          startDate,
          endDate,
        }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
